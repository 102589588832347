import { Flex, Stack, Title, Card, Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

// import { DownloadCenterBaseRoute } from 'modules/downloadcenter/Routes';
import { FileBaseRoute } from 'modules/files/Routes';
// import { SoftwareConfigBaseRoute } from 'modules/softwareconfig/Routes';
import { BaseLayout } from 'shared/components/baseLayout';
// import DownloadCenter from './assets/downloadCenter.svg';
import FeedbackFiles from './assets/feedbackfiles.svg';
// import SoftwareConfig from './assets/softwareconfig.svg';

export const Home = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const redirectRoot = localStorage.getItem('redirectRoot');

    const handleChosenOption = (option: string) => {
        localStorage.setItem('redirectRoot', option);
    };

    if (redirectRoot && !location.state?.preventRedirect) {
        return <Navigate to={`/${redirectRoot}`} />;
    }

    return (
        <BaseLayout showNavBar={false}>
            <Stack align="center">
                <Title my="xl" order={3}>
                    {t('chooseWhatToManage')}
                </Title>
                <Flex gap="xl" justify="center" wrap="wrap">
                    {/* <Card
                        to={`/${SoftwareConfigBaseRoute}`}
                        shadow="lg"
                        radius="md"
                        p="xl"
                        component={Link}
                        onClick={() => handleChosenOption(SoftwareConfigBaseRoute)}
                    >
                        <Stack>
                            <Image src={SoftwareConfig} width={300} height={300} fit="contain" />
                            <Title order={3} align="center">
                                {t('softwareconfiguration')}
                            </Title>
                        </Stack>
                    </Card> */}
                    <Card
                        to={`/${FileBaseRoute}`}
                        shadow="lg"
                        radius="md"
                        p="xl"
                        component={Link}
                        onClick={() => handleChosenOption(FileBaseRoute)}
                    >
                        <Stack>
                            <Image src={FeedbackFiles} width={300} height={300} fit="contain" />
                            <Title order={3} align="center">
                                {t('myFeedbackFiles')}
                            </Title>
                        </Stack>
                    </Card>
                    {/* <Card
                        to={`/${DownloadCenterBaseRoute}`}
                        shadow="lg"
                        radius="md"
                        p="xl"
                        component={Link}
                        onClick={() => handleChosenOption(DownloadCenterBaseRoute)}
                    >
                        <Stack>
                            <Image src={DownloadCenter} width={300} height={300} fit="contain" />
                            <Title order={3} align="center">
                                {t('downloadCenter')}
                            </Title>
                        </Stack>
                    </Card>                              */}
                </Flex>
            </Stack>
        </BaseLayout>
    );
};
